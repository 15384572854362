import {
  IconShoppingBag,
  IconReport,
  IconPackages,
  IconHomeStats,
  IconAlertOctagon,
  IconBuildingStore,
  IconChecklist,
  IconPhotoCheck,
  IconUsersGroup,
  IconUsers,
  IconReportAnalytics,
  IconBuildingBank,
  IconInbox,
  IconBrandAmazon,
  IconBrandEtsy,
  IconBrandFacebook,
  IconListDetails,
  IconBuildingWarehouse,
  IconScale,
  IconPalette,
  IconSettingsAutomation,
  IconBrandTeams,
  IconCircles,
  IconBuildingEstate,
  IconLockAccess,
  IconHomeDollar,
  IconColorFilter,
  IconBlender,
  IconBriefcase,
  IconBuildingFactory2,
  IconLockSquare,
  IconApi,
  IconCubeSend,
  IconBrandTiktok,
  IconBrandReason,
  IconAffiliate,
  IconTrack,
  IconTemplate,
  IconBoxMultiple,
  IconFileDescription,
  IconBasketDollar,
  IconReportSearch,
} from '@tabler/icons-react'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'

import { ShopifyIcon, EbayIcon, LogoIcon } from '@/assets/icons'
import { VIEW_PERMS } from '@/config/permissions'
import { useAuthorization } from '@/lib/authorization'

export function useNav() {
  const location = useLocation()
  const { checkAccess } = useAuthorization()

  const modules = [
    checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_ADMIN] }) && {
      name: 'admin',
      icon: <IconSettingsAutomation size={20} />,
      items: getAdminItems(),
    },
    checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_FINANCE] }) && {
      name: 'finance',
      icon: <IconBuildingBank size={20} />,
      items: getFinanceItems(),
    },
    checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_SALE] }) && {
      name: 'sales',
      icon: <IconHomeStats size={20} />,
      items: getSalesItems(),
    },
    checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_FF] }) && {
      name: 'fulfillment',
      icon: <IconScale size={20} />,
      items: getFulfillmentItems(),
    },
    checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_DESIGN] }) && {
      name: 'design',
      icon: <IconPalette size={20} />,
      items: getDesignItems(),
    },
    checkAccess({
      allowedPermissions: [
        VIEW_PERMS.VIEW_VENDOR,
        VIEW_PERMS.VIEW_PROD_TYPE_DS,
      ],
    }) && {
      name: 'vendor',
      icon: <IconBuildingEstate size={20} />,
      items: getVendorItems(),
    },
  ].filter(Boolean)

  const currentModule = modules.find((m) => {
    return _.startsWith(location.pathname, '/' + m.name)
  })

  return { currentModule, modules }

  function getAdminItems() {
    return [
      {
        index: true,
        title: 'Users',
        to: '/admin/users',
        icon: <IconUsers size={18} />,
      },
      {
        title: 'Teams',
        to: '/admin/teams',
        icon: <IconBrandTeams size={18} />,
      },
      {
        title: 'Roles',
        to: '/admin/roles',
        icon: <IconCircles size={18} />,
      },
      {
        title: 'Permissions',
        to: '/admin/permissions',
        icon: <IconLockAccess size={18} />,
      },
      {
        title: 'Policies',
        to: '/admin/policies',
        icon: <IconLockSquare size={18} />,
        items: [
          {
            title: 'Resources',
            to: '/admin/policies/resources',
            icon: <IconCubeSend size={18} />,
          },
          {
            title: 'API',
            to: '/admin/policies/api',
            icon: <IconApi size={18} />,
          },
        ],
      },
    ]
  }

  function getSalesItems() {
    return [
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_ORDER] }) && {
        title: 'Orders',
        to: '/sales/orders',
        icon: <IconInbox size={18} />,
        items: [
          {
            index: true,
            title: 'Amazon',
            to: '/sales/orders/amazon',
            icon: <IconBrandAmazon size={18} />,
          },
          {
            title: 'Shopify',
            to: '/sales/orders/shopify',
            icon: <ShopifyIcon size={18} />,
          },
          {
            title: 'Etsy',
            to: '/sales/orders/etsy',
            icon: <IconBrandEtsy size={18} />,
          },
          {
            title: 'Shopbase',
            to: '/sales/orders/shopbase',
            icon: <IconShoppingBag size={18} />,
          },
          {
            title: 'Facebook',
            to: '/sales/orders/facebook_marketplace',
            icon: <IconBrandFacebook size={18} />,
          },
          {
            title: 'Ebay',
            to: '/sales/orders/ebay',
            icon: <EbayIcon size={18} />,
          },
          {
            title: 'Tiktok',
            to: '/sales/orders/tiktok',
            icon: <IconBrandTiktok size={18} />,
          },
        ],
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_LISTING] }) && {
        title: 'Listings',
        to: '/sales/listing',
        icon: <IconListDetails size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_COST_CENTER] }) && {
        title: 'Cost Centers',
        to: '/sales/cost-centers',
        icon: <IconHomeDollar size={18} />,
        items: [
          {
            title: 'Config',
            to: '/sales/cost-centers/config',
            icon: <IconInbox size={18} />,
          },
          {
            title: 'Mapping',
            to: '/sales/cost-centers/mapping',
            icon: <IconColorFilter size={18} />,
          },
          {
            title: 'Group Standards',
            to: '/sales/cost-centers/group-standards',
            icon: <IconUsersGroup size={18} />,
          },
          {
            title: 'Product Types',
            to: '/sales/cost-centers/product-types',
            icon: <IconPackages size={18} />,
          },
        ],
      },
      {
        title: 'Report',
        to: '/sales/report',
        icon: <IconInbox size={18} />,
        items: [
          checkAccess({
            allowedPermissions: [VIEW_PERMS.VIEW_ORDER],
          }) && {
            title: 'Orders Cost',
            to: '/sales/report/orders-cost',
            icon: <IconBasketDollar size={18} />,
            items: [
              {
                title: 'Amazon',
                to: '/sales/report/orders-cost/amazon',
                icon: <IconBrandAmazon size={18} />,
              },
              {
                title: 'Shopify',
                to: '/sales/report/orders-cost/shopify',
                icon: <ShopifyIcon size={18} />,
              },
              {
                title: 'Etsy',
                to: '/sales/report/orders-cost/etsy',
                icon: <IconBrandEtsy size={18} />,
              },
              {
                title: 'Shopbase',
                to: '/sales/report/orders-cost/shopbase',
                icon: <IconShoppingBag size={18} />,
              },
              {
                title: 'Tiktok',
                to: '/sales/report/orders-cost/tiktok',
                icon: <IconBrandTiktok size={18} />,
              },
            ],
          },
          checkAccess({
            allowedPermissions: [VIEW_PERMS.VIEW_SALE_REPORT],
          }) && {
            title: 'Sale Report',
            to: '/sales/report/sale-report',
            icon: <IconReportSearch size={18} />,
            items: [
              {
                title: 'Amazon',
                to: '/sales/report/sale-report/amazon',
                icon: <IconBrandAmazon size={18} />,
              },
              {
                title: 'Shopify',
                to: '/sales/report/sale-report/shopify',
                icon: <ShopifyIcon size={18} />,
              },
              {
                title: 'Etsy',
                to: '/sales/report/sale-report/etsy',
                icon: <IconBrandEtsy size={18} />,
              },
              {
                title: 'Shopbase',
                to: '/sales/report/sale-report/shopbase',
                icon: <IconShoppingBag size={18} />,
              },
              {
                title: 'Tiktok',
                to: '/sales/report/sale-report/tiktok',
                icon: <IconBrandTiktok size={18} />,
              },
            ],
          },
        ],
      },
      {
        title: 'Monitoring',
        to: '/sales/monitoring',
        icon: <IconReport size={18} />,
        items: [
          {
            title: 'Order Missing Sync',
            to: '/sales/monitoring/order-missing-sync',
            icon: <IconReport size={18} />,
          },
          checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_TRACKING] }) && {
            title: 'Tracking',
            to: '/sales/monitoring/tracking',
            icon: <IconTrack size={18} />,
            items: [
              {
                title: 'Report',
                to: '/sales/monitoring/tracking',
                icon: <IconReportAnalytics size={18} />,
              },
              {
                title: 'Details',
                to: '/sales/monitoring/tracking/details',
                icon: <IconFileDescription size={18} />,
              },
            ],
          },
        ],
      },
      {
        title: 'Amz Confirm Ship and Tracking',
        to: '/sales/confirm-ship-and-tracking',
        icon: <IconReport size={18} />,
      },
      {
        title: 'Configuration',
        to: '/sales/configuration',
        icon: <IconSettingsAutomation size={18} />,
        items: [
          checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_CASE] }) && {
            title: 'Cases',
            to: '/sales/configuration/cases',
            icon: <IconBriefcase size={18} />,
          },
          checkAccess({
            allowedPermissions: [VIEW_PERMS.VIEW_REFUND_REASON],
          }) && {
            title: 'Refund Reasons',
            to: '/sales/configuration/refund-reasons',
            icon: <IconBrandReason size={18} />,
          },
          {
            title: 'Etsy Customization',
            to: '/sales/configuration/etsy-customization',
            icon: <IconBrandEtsy size={18} />,
            items: [
              {
                title: 'Etsy Templates',
                to: '/sales/configuration/etsy-customization/etsy-templates',
                icon: <IconTemplate size={18} />,
              },
              {
                title: 'Bulk Fill Data',
                to: '/sales/configuration/etsy-customization/bulk-fill-customization',
                icon: <IconBrandReason size={18} />,
              },
            ],
          },
          checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_AMZ_STORES] }) && {
            title: 'Manage stores',
            to: '/sales/configuration/manage-stores',
            icon: <IconBuildingStore size={18} />,
          },
          checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_SKU] }) && {
            title: 'SKU Mapping',
            to: '/sales/configuration/sku-mapping',
            icon: <IconInbox size={18} />,
            items: [
              {
                title: 'Amazon',
                to: '/sales/configuration/sku-mapping/amazon',
                icon: <IconBrandAmazon size={18} />,
              },
              {
                title: 'Shopify',
                to: '/sales/configuration/sku-mapping/shopify',
                icon: <ShopifyIcon size={18} />,
              },
              {
                title: 'Etsy',
                to: '/sales/configuration/sku-mapping/etsy',
                icon: <IconBrandEtsy size={18} />,
              },
              {
                title: 'Shopbase',
                to: '/sales/configuration/sku-mapping/shopbase',
                icon: <IconShoppingBag size={18} />,
              },
              {
                title: 'Ebay',
                to: '/sales/configuration/sku-mapping/ebay',
                icon: <EbayIcon size={18} />,
              },
            ],
          },
        ],
      },
    ].filter(Boolean)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getFinanceItems() {
    return [
      // {
      //   index: true,
      //   title: 'Overview',
      //   to: '/finance',
      //   icon: <IconLayoutDashboard size={18} />,
      // },
      // {
      //   title: 'Transaction Classify',
      //   to: '/finance/transaction-classify',
      //   icon: <IconTriangleSquareCircle size={18} />,
      // },
      // {
      //   title: 'Reports',
      //   to: '/finance/reports',
      //   icon: <IconReportAnalytics size={18} />,
      //   items: [
      //     {
      //       title: 'Cut Off',
      //       to: '/finance/reports/cut-off',
      //       icon: <IconTrendingUp size={18} />,
      //     },
      //     {
      //       title: 'Revenue with Basecost',
      //       to: '/finance/reports/revenue-basecost',
      //       icon: <IconCashRegister size={18} />,
      //     },
      //   ],
      // },
      // {
      //   title: 'Banks',
      //   to: '/finance/banks',
      //   icon: <IconBuildingBank size={18} />,
      //   items: [
      //     {
      //       title: 'Paypal',
      //       to: '/finance/banks/paypal',
      //       icon: <IconBrandPaypal size={18} />,
      //     },
      //     {
      //       title: 'Payoneer',
      //       to: '/finance/banks/payoneer',
      //       icon: <IconInnerShadowTopRight size={18} />,
      //     },
      //     {
      //       title: 'PingPong',
      //       to: '/finance/banks/pingpong',
      //       icon: <IconPingPong size={18} />,
      //     },
      //     {
      //       title: 'Bankwise',
      //       to: '/finance/banks/bankwise',
      //       icon: <IconCashBanknote size={18} />,
      //     },
      //   ],
      // },
      // {
      //   title: 'Payment Processors',
      //   to: '/finance/payment-processors',
      //   icon: <IconCpu size={18} />,
      //   items: [
      //     {
      //       title: 'Stripe',
      //       to: '/finance/payment-processors/stripe',
      //       icon: <IconBrandStripe size={18} />,
      //     },
      //     {
      //       title: 'Shopify Payments',
      //       to: '/finance/payment-processors/shopify-payments',
      //       icon: <IconShoppingBag size={18} />,
      //     },
      //   ],
      // },
      // {
      //   title: 'Cost Reports',
      //   to: '/finance/cost-reports',
      //   icon: <IconReport size={18} />,
      //   items: [
      //     {
      //       title: 'Vendor Cost',
      //       to: '/finance/cost-reports/vendor-cost',
      //       icon: <IconPackages size={18} />,
      //     },
      //     {
      //       title: 'Seller Cost',
      //       to: '/finance/cost-reports/seller-cost',
      //       icon: <IconBusinessplan size={18} />,
      //     },
      //   ],
      // },
      // {
      //   title: 'Base Cost',
      //   to: '/finance/base-cost',
      //   icon: <IconReportMoney size={18} />,
      //   items: [
      //     {
      //       title: 'Summary',
      //       to: '/finance/base-cost/summary',
      //       icon: <IconHomeStats size={18} />,
      //     },
      //     {
      //       title: 'Vendor Transactions',
      //       to: '/finance/base-cost/vendor-transactions',
      //       icon: <IconCreditCardPay size={18} />,
      //     },
      //     {
      //       title: 'Incorrect Base Cost',
      //       to: '/finance/base-cost/incorrect-base-cost',
      //       icon: <IconAlertOctagon size={18} />,
      //     },
      //   ],
      // },
      // {
      //   title: 'Purchase Contracts',
      //   to: '/finance/purchase-contracts',
      //   icon: <IconContract size={18} />,
      // },
      // {
      //   title: 'Revenue Oversea',
      //   to: '/finance/revenue-oversea',
      //   icon: <IconWorldDollar size={18} />,
      // },
      // {
      //   title: 'Vendor Products',
      //   to: '/finance/vendor-products',
      //   icon: <IconBuildingStore size={18} />,
      // },
    ].filter(Boolean)
  }

  function getFulfillmentItems() {
    return [
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_SCALE] }) && {
        title: 'Scale',
        to: '/fulfillment/scale',
        icon: <IconScale size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_QC] }) && {
        title: 'QC',
        to: '/fulfillment/qc',
        icon: <IconBlender size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_AUTO_FF] }) && {
        title: 'Auto Fulfillment',
        to: '/fulfillment/auto-fulfillment',
        icon: <IconAffiliate size={18} />,
        items: [
          {
            title: 'Amazon',
            to: '/fulfillment/auto-fulfillment/amazon',
            icon: <IconBrandAmazon size={18} />,
          },
          {
            title: 'Shopify',
            to: '/fulfillment/auto-fulfillment/shopify',
            icon: <ShopifyIcon size={18} />,
          },
        ],
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_AS_TOOL] }) && {
        title: 'Asin Tools',
        to: '/fulfillment/asin-tools',
        icon: <IconBriefcase size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_LISTING_TYPE] }) && {
        title: 'Listing Types',
        to: '/fulfillment/listing-types',
        icon: <IconBuildingStore size={18} />,
      },
      {
        title: 'Working Status',
        to: '/fulfillment/working-status',
        icon: <IconHomeStats size={18} />,
      },

      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_FF_REPORT] }) && {
        title: 'Reports',
        to: '/fulfillment/reports',
        icon: <IconReport size={18} />,
        items: [
          {
            title: 'Vendor Fulfillment',
            to: '/fulfillment/reports/vendor-fulfillment',
            icon: <IconBuildingFactory2 size={18} />,
          },
          {
            title: 'Vendor Pending',
            to: '/fulfillment/reports/vendor-pending',
            icon: <IconBuildingWarehouse size={18} />,
          },
          {
            title: 'GOD Pending',
            to: '/fulfillment/reports/god-pending',
            icon: <LogoIcon size={18} />,
          },
          {
            title: 'Working Results',
            to: '/fulfillment/reports/working-results',
            icon: <IconReport size={18} />,
          },
        ],
      },
    ].filter(Boolean)
  }

  function getDesignItems() {
    return [
      checkAccess({
        allowedPermissions: [VIEW_PERMS.VIEW_GROUP_PRODUCT_TYPE],
      }) && {
        title: 'Group Product Type',
        to: '/design/group-product-type',
        icon: <IconBoxMultiple size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_PSD_TASK] }) && {
        title: 'PSD Tasks',
        to: '/design/psd-tasks',
        icon: <IconChecklist size={18} />,
        items: [
          {
            index: true,
            title: 'Team Idea',
            to: '/design/psd-tasks/team-idea',
            icon: <IconBrandTeams size={18} />,
          },
          {
            title: 'Team Clone',
            to: '/design/psd-tasks/team-clone',
            icon: <IconBrandTeams size={18} />,
          },
        ],
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_PNG_TASK] }) && {
        title: 'PNG Tasks',
        to: '/design/png-tasks',
        icon: <IconPhotoCheck size={18} />,
      },
      {
        title: 'Embroider Listing',
        to: '/design/embroider-listing',
        icon: <IconListDetails size={18} />,
      },
    ].filter(Boolean)
  }

  function getVendorItems() {
    return [
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_VENDOR] }) && {
        index: true,
        title: 'List Vendor',
        to: '/vendor/list-vendor',
        icon: <IconBuildingWarehouse size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_VENDOR] }) && {
        title: 'Token Vendor',
        to: '/vendor/token-vendor',
        icon: <IconAlertOctagon size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_VENDOR] }) && {
        title: 'Vendor Products',
        to: '/vendor/vendor-products',
        icon: <IconBuildingStore size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_VENDOR] }) && {
        title: 'Store Fulfill',
        to: '/vendor/store-fulfill',
        icon: <IconBuildingStore size={18} />,
      },
      checkAccess({ allowedPermissions: [VIEW_PERMS.VIEW_PROD_TYPE_DS] }) && {
        title: 'DS Product Types',
        to: '/vendor/design-product-types',
        icon: <IconPackages size={18} />,
      },
    ].filter(Boolean)
  }
}

export function useCheckActiveNav() {
  const location = useLocation()

  const checkActiveNav = (nav: string | undefined, isOrders?: boolean) => {
    const pathArray = location.pathname.split('/').filter((item) => item !== '')

    if (pathArray.length === 0) {
      return false
    }

    if (isOrders) {
      return pathArray.at(-1) === 'orders'
    }

    if (!nav) {
      return false
    }

    const navToCheck = nav.replace(/^\//, '')

    const navArray = navToCheck.split('/').filter((item) => item !== '')

    if (navArray.length != pathArray.length) {
      return false
    }

    return navArray.every((item, index) => item === pathArray[index])
  }

  return {
    checkActiveNav,
  }
}
